@font-face {
  font-family: Neue Regrade Variable;
  src: url("NeueRegradeVariable.7cccf263.ttf") format("truetype");
}

:root {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

@supports (font-variation-settings: normal) {
  :root, * {
    font-family: Neue Regrade Variable, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  }
}

body {
  color: #fff;
  background-color: #000;
  margin: 0;
  padding: 0;
}

button {
  color: #fff;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
}

.muted {
  color: #a3a3a3;
}

.logo {
  font-size: 2em;
  line-height: .9em;
}

.logo-sikko {
  font-weight: 500;
}

.logo-medema {
  font-weight: 800;
}

@media (width >= 1024px) {
  .logo {
    z-index: 2000;
    color: #fff;
    position: fixed;
    top: 40px;
    left: 40px;
  }
}

.canvas-container {
  z-index: 1;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.info-button {
  opacity: .8;
  z-index: 999;
  justify-content: center;
  transition: opacity .2s ease-in-out;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  overflow: hidden;
}

.info-button:hover {
  opacity: 1;
}

.info-button > button {
  color: #fff;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 5px;
  outline: none;
  margin: 10px;
  padding: 10px;
  font-size: 24px;
}

.menu-list {
  justify-content: center;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 50px;
  left: 0;
  overflow: hidden;
}

.menu-list > button {
  color: #fff;
  cursor: pointer;
  opacity: .5;
  background: none;
  border: none;
  border-radius: 5px;
  outline: none;
  margin: 10px 20px;
  padding: 10px;
  font-size: 28px;
  font-weight: 500;
  transition: opacity .2s ease-in-out;
}

.menu-list > button.active, .menu-list > button:hover {
  opacity: 1;
}

.prev-container, .next-container {
  z-index: 999;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  left: 0;
}

.prev-container {
  top: 0;
}

.next-container {
  bottom: 0;
}

.nav-button {
  color: #fff;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 5px;
  outline: none;
  margin: 10px;
  padding: 10px;
  font-size: 32px;
}

.scene-section {
  width: 400px;
  font-size: 18px;
}

.scene-section h1, .people-section h2 {
  font-size: 32px;
  font-weight: 700;
}

.scene-section a {
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  transition: color .2s ease-in-out;
}

.projects-section {
  position: relative;
}

ul.projects-list {
  justify-content: center;
  align-items: left;
  color: #fff;
  flex-direction: column;
  width: 500px;
  margin: 0;
  padding: 150px 0 0;
  list-style: none;
  display: flex;
  position: absolute;
  top: 0;
  left: -30px;
}

ul.projects-list:hover {
  color: #ffffff4d;
}

.project-button {
  color: inherit;
  cursor: pointer;
  text-align: left;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  align-items: center;
  padding: 8px 0;
  font-size: 32px;
  font-weight: 700;
  transition: color .2s ease-in-out, transform .2s ease-in-out;
  display: flex;
}

.project-arrow {
  opacity: 0;
  flex: 0 0 30px;
  transition: opacity .2s ease-in-out;
}

.project-button:hover {
  color: #fff;
  transform: translateX(20px);
}

.project-button:hover .project-arrow {
  opacity: 1;
}

.single-project {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.project-back-button {
  cursor: pointer;
  align-items: center;
  padding: 5px 0;
  font-size: 16px;
  transition: transform .2s ease-in-out;
  display: flex;
}

.project-back-button:hover {
  transform: translateX(-18px);
}

.project-back-button:hover > svg {
  opacity: .3;
}

.project-back-button > svg {
  margin-right: 5px;
  transition: opacity .2s ease-in-out;
}

.play-button {
  cursor: pointer;
  border: 1px solid #fff;
  align-items: center;
  padding: 10px 15px 10px 10px;
  font-size: 18px;
  font-weight: 700;
  transition: background-color .2s ease-in-out, color .2s ease-in-out;
  display: flex;
}

.play-button:hover {
  color: #000;
  background-color: #fff;
}

.play-button > svg {
  margin-right: 5px;
}

.info-section {
  pointer-events: none;
  font-size: 1.05em;
}

.info-section a {
  pointer-events: auto;
}

.info-section .copyright {
  opacity: .5;
}

.cc-attribution {
  font-size: .8em;
  display: flex;
}

.cc-attribution p {
  opacity: .5;
  width: 50%;
  margin: 0;
  display: block;
}

.modal-backdrop {
  z-index: 1000;
  background-color: #000000e6;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-close {
  z-index: 1002;
  font-size: 30px;
  position: fixed;
  top: 30px;
  right: 30px;
}

.modal {
  z-index: 1001;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-inner {
  pointer-events: auto;
  color: #fff;
  width: calc(100% - 100px);
  max-width: 1280px;
  height: calc(100% - 100px);
  max-height: 720px;
  position: relative;
  overflow: auto;
}

.modal-fader {
  z-index: 1000;
  position: relative;
}

.request-landscape {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  display: flex;
}

@media (width <= 1023px) {
  .logo-float {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.mobile-container, .logo-container {
  max-width: 700px;
  margin: auto;
  padding: 20px;
}

.mobile-project {
  margin-bottom: 5rem;
}

.mobile-video {
  width: 100%;
  height: auto;
}

.mobile-header {
  height: 90px;
}

.mobile-container a {
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  transition: color .2s ease-in-out;
}

.mobile-container h1 {
  font-size: 32px;
  font-weight: 700;
}

.mobile-container h2 {
  font-size: 24px;
  font-weight: 700;
}

.mobile-container footer {
  margin-top: 5rem;
}

.player-wrapper {
  position: relative;
}

.player-wrapper .react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.view-on-desktop {
  border-bottom: 1px solid #ffffff80;
  padding-bottom: 1em;
}

.loader-container {
  z-index: 1000;
  background-color: #000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.loader-inner {
  width: 100px;
  height: 100px;
  position: relative;
}

.mobile-about, .mobile-people {
  margin-bottom: 4rem;
}

.bold-white strong {
  color: #fff;
}
/*# sourceMappingURL=index.33dbd178.css.map */
