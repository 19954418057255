@font-face {
  font-family: "Neue Regrade Variable";
  src: url("../static/NeueRegradeVariable.ttf") format("truetype");
}

:root{
  font-family: -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}
@supports (font-variation-settings: normal) {
  :root, * {
    font-family: "Neue Regrade Variable", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  }
}

body {
  /* background-color: #0a0a0a; */
  background-color: #000000;
  color: #ffffff;
  padding: 0;
  margin: 0;
}

button {
  background: none;
  outline: none;
  border: none;
  border-radius: 0;
  color: #ffffff;
}

p {
  /* text-align: justify; */
}

.muted {
  color: #a3a3a3;
}

.logo {
  font-size: 2em;
  line-height: 0.9em;
}

.logo-sikko {
  font-weight: 500;
}

.logo-medema {
  font-weight: 800;
}

@media (min-width: 1024px) {
  .logo {
    position: fixed;
    left: 40px;
    top: 40px;
    z-index: 2000;
    color: #ffffff;
  }
}

.canvas-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
}

.info-button {
  position: absolute;
  top: 10px;
  right: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
  z-index: 999;
}

.info-button:hover {
  opacity: 1;
}

.info-button > button {
  background: none;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
}

.menu-list {
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.menu-list > button {
  background: none;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 20px;
  color: #ffffff;
  font-size: 28px;
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  opacity: 0.5;
}

.menu-list > button.active,
.menu-list > button:hover {
  opacity: 1;
}

.prev-container,
.next-container {
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.prev-container {
  top: 0;
}

.next-container {
  bottom: 0;
}

.nav-button {
  background: none;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  color: #ffffff;
  font-size: 32px;
  cursor: pointer;
}

.scene-section {
  width: 400px;
  font-size: 18px;
}

.scene-section h1 {
  font-size: 32px;
  font-weight: 700;
}

.people-section h2 {
  font-size: 32px;
  font-weight: 700;
}

.scene-section a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  font-weight: 700;
}

.projects-section {
  position: relative;
}

ul.projects-list {
  position: absolute;
  top: 0;
  left: -30px;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  color: #ffffff;
  width: 500px;
  padding-top: 150px;
}

ul.projects-list:hover {
  color: rgba(255, 255, 255, 0.3);
}

.project-button {
  background: none;
  outline: none;
  border: none;
  border-radius: 0;
  padding: 8px 0;
  color: inherit;
  font-size: 32px;
  font-weight: 700;
  cursor: pointer;
  text-align: left;
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
  display: flex;
  align-items: center;
}

.project-arrow {
  flex: 0 0 30px;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.project-button:hover {
  color: #ffffff;
  transform: translateX(20px);
}

.project-button:hover .project-arrow {
  opacity: 1;
}

.single-project {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.project-back-button {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  padding: 5px 0;
  transition: transform 0.2s ease-in-out;
}

.project-back-button:hover {
  transform: translateX(-18px);
}

.project-back-button:hover > svg {
  opacity: 0.3;
}

.project-back-button > svg {
  margin-right: 5px;
  transition: opacity 0.2s ease-in-out;
}

.play-button {
  display: flex;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  padding: 10px 15px 10px 10px;
  font-weight: 700;
  border: 1px solid #ffffff;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.play-button:hover {
  background-color: #ffffff;
  color: #000000;
}

.play-button > svg {
  margin-right: 5px;
}

.info-section {
  pointer-events: none;
  font-size: 1.05em;
}

.info-section a {
  pointer-events: auto;
}

.info-section .copyright {
  opacity: 0.5;
}

.cc-attribution {
  display: flex;
  font-size: 0.8em;
}

.cc-attribution p {
  margin: 0;
  display: block;
  width: 50%;
  opacity: 0.5;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
}

.modal-close {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 1002;
  font-size: 30px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.modal-inner {
  pointer-events: auto;
  position: relative;
  color: #ffffff;
  overflow: auto;
  max-width: 1280px;
  max-height: 720px;
  width: calc(100% - 100px);
  height: calc(100% - 100px);
}

.modal-fader {
  position: relative;
  z-index: 1000;
}

.request-landscape {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 100vh;
  text-align: center;
}

@media (max-width: 1023px) {
  .logo-float {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.mobile-container,
.logo-container {
  margin: auto;
  max-width: 700px;
  padding: 20px;
}

.mobile-project {
  margin-bottom: 5rem;
}

.mobile-video {
  width: 100%;
  height: auto;
}

.mobile-header {
  height: 90px;
}

.mobile-container a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  font-weight: 700;
}

.mobile-container h1 {
  font-size: 32px;
  font-weight: 700;
}

.mobile-container h2 {
  font-size: 24px;
  font-weight: 700;
}

.mobile-container footer {
  margin-top: 5rem;
}

.player-wrapper {
  position: relative;
  /* padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.player-wrapper .react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.view-on-desktop {
  padding-bottom: 1em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-inner {
  position: relative;
  width: 100px;
  height: 100px;
}

.mobile-about,
.mobile-people {
  margin-bottom: 4rem;
}

.bold-white strong {
  color: #ffffff;
}
